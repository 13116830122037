import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Tailored = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Funeral Cover Tailored for POPCRU Members"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Funeral Cover for Dignified Burials",
          label:
            "The POPCRU 25 Funeral Benefit offers more than just financial support; it offers the promise of dignified farewells. Your family deserves to say goodbye without the financial burden, and this product guarantees just that.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Affordable Funeral Insurance",
          label:
            "We recognise the importance of affordable funeral insurance for our valued POPCRU members. This product is tailored to be accessible without straining your budget.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Funeral Insurance for Senior Citizens",
          label:
            "Senior citizens within the POPCRU community can find solace in knowing that their funeral expenses are covered. This product caters to the unique needs of senior members.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Funeral Cover Policies",
          label:
            "Our policies are comprehensive and designed to meet the specific needs of the POPCRU community. Whether you're a main member, spouse, or extended family, we have you covered.",
        },
      ]}
    />
  )
}

export default Tailored
