import React from "react"
import styled from "styled-components"

import { COVER_LINK, PRODUCT_COLOR } from "."
import { CoverButton } from "../../../elements"

const Conclusion = (): JSX.Element => {
  return (
    <Container>
      <div className="sectionHeader">
        <h2>Secure Your Family&apos;s Financial Future</h2>
      </div>

      <p>
        The POPCRU 25 Funeral Benefit is more than just an insurance product;
        it&apos;s a promise of security and support during difficult moments.
        With cover tailored to POPCRU members, their spouses, and up to 7
        children, it ensures that your family receives the financial support
        they deserve. When you choose Workerslife, you&apos;re choosing
        dignified farewells and financial peace of mind for your loved ones.
      </p>
      <div className="buttonContainer">
        <CoverButton
          coverType={COVER_LINK}
          filled
          buttonColor={PRODUCT_COLOR}
          textColor="#fff"
          label="Buy Now"
        />
      </div>
      <p className="styledFooter">
        With Workerslife, securing your family&apos;s financial future is a
        reality. Invest in the POPCRU 25 Funeral Benefit and provide your loved
        ones with the support they deserve during challenging times.
      </p>
    </Container>
  )
}

export default Conclusion

const Container = styled.div`
  p {
    padding: 0 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
  .styledFooter {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
  .coverButton {
    margin: 60px auto;
  }
  .buttonContainer {
    padding: 0 20px;
  }
  .sectionHeader {
    margin: 60px auto 40px auto;
    max-width: 970px;
    padding: 0 30px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin: 40px auto 90px auto;

      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 100px auto 40px auto;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 auto 90px auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .buttonContainer {
      padding: 0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    p,
    .sectionHeader {
      padding: 0 60px;
    }
  }
`
