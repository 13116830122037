import theme from "../../../theme"
export const PRODUCT_COLOR = theme.palette.custom.yellow

import { CoverType } from "../../../elements/CoverButton"
export const COVER_LINK = CoverType.POPCRU_FUNERAL

export { default as Article } from "./Article"
export { default as Benefits } from "./Benefits"
export { default as Perks } from "./Perks"
export { default as SEO } from "./SEO"
export { default as Conclusion } from "./Conclusion"
export { default as Tailored } from "./Tailored"
