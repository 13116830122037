import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/popcru-25-funeral-benefit/"

const Benefits = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="POPCRU 25 Funeral product benefits..."
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Maximum Coverage of Up to R60,000",
          label:
            "As a POPCRU member, you can get a maximum cover of up to R60,000 for the main member and their spouse. This substantial coverage ensures that your family is financially secure during challenging times.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Repatriation Benefit",
          label:
            "Our product includes a repatriation benefit of R7,500 per claim, up to a maximum of R20,000 per year. This benefit ensures that your loved ones can be transported home for a dignified burial, even if they're far away.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Premium Waiver on Death",
          label:
            "In the unfortunate event of the main member's passing, we understand the financial impact. That's why we offer a premium waiver of 60 months to provide relief during this challenging period.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-4.png`} {...ImageProperties} />
          ),
          topic: "Cash Benefits for Children",
          label:
            "Your children receive up to R20,000 in cash, depending on their age. This financial support ensures they can focus on their future without worrying about expenses.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-5.png`} {...ImageProperties} />
          ),
          topic: "Extended Family Cover",
          label:
            "The POPCRU 25 Funeral Benefit extends its coverage to up to 10 extended family members. We understand the importance of supporting the entire family network during difficult times.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-5.png`} {...ImageProperties} />
          ),
          topic: "Grocery Voucher and Vehicle Assist",
          label:
            "As a token of our support, we provide a grocery voucher to the value of R1,000 and a vehicle assist benefit for a period of 3 days. These benefits offer practical assistance during challenging times.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-5.png`} {...ImageProperties} />
          ),
          topic: "Once-off Airtime Benefit",
          label:
            "We understand that communication is crucial during such times. That's why we offer a once-off airtime benefit of R250, ensuring that you can stay connected when you need it the most.",
        },
      ]}
    />
  )
}

export default Benefits
