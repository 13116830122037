import React from "react"
import styled from "styled-components"

import { ProductArticleExtraContent } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const Article = (): JSX.Element => {
  return (
    <Container>
      <ProductArticleExtraContent
        color={PRODUCT_COLOR}
        title="POPCRU 25 Funeral Benefit"
        coverType={COVER_LINK}
        subTitle="Explore the enhanced funeral expense benefits exclusively designed for POPCRU members, their spouses, and extended family. Secure the financial future of your loved ones with affordable funeral insurance."
        topic="Comprehensive Funeral Coverage for POPCRU Members"
      >
        <p>
          At Workerslife, we understand the importance of providing dignified
          farewells and securing your family&apos;s financial well-being during
          challenging times. That&apos;s why we&apos;re proud to introduce the
          POPCRU 25 Funeral Benefit, a specialised funeral insurance product
          exclusively for POPCRU members, their spouses, and up to 7 children
          (max age 26). This product is designed to provide enhanced funeral
          expense benefits, ensuring that your loved ones receive the support
          they deserve during difficult moments.
        </p>
      </ProductArticleExtraContent>
    </Container>
  )
}

export default Article

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
`
