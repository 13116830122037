import React from "react"
import styled from "styled-components"

import {
  Article,
  Benefits,
  SEO,
  Perks,
  Tailored,
  Conclusion,
} from "../../sections/products/popcru-25-funeral"

const ComprehensiveFuneralBenefit: React.FC = () => (
  <PageWrapper>
    <SEO />
    <Article />
    <Tailored />
    <Benefits />
    <Conclusion />
  </PageWrapper>
)

export default ComprehensiveFuneralBenefit

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`
